import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import GlobalContext from "./../GlobalContext";
import {
  EventGridHolder,
  EventGridDiv,
  EventCardDiv,
  DateCardDiv,
  EmptyEventGridDiv,
} from "./elements";
import {
  getShortDayNoTimeZone,
  getDateDayNoTimeZone,
  cleanCraftDate,
} from "./../../modules/time";
import { getEventTypeFromID } from "./../../modules/eventTypes";
import { H6, H6Medium, H6SmallMedium, H5Strong } from "./../Typography";

// const getArticleTypeFromID = (id) => {
//   console.log(id);
//   return id;
// };
const DateCard = ({ date, dateAsDate, timeFrame }) => {
  // date is coming in as a date that has been turned into a string
  // date is always coming in as a string
  // console.log("\n\nDateCard 21: ", date, typeof date);
  const startDate = new Date(date);
  /* THIS IS WRONG */
  const startDateString = getDateDayNoTimeZone(date);
  // console.log(date, startDate, startDateString, getDateDay(startDate));

  let outDate = startDateString;
  if (timeFrame === "thisWeekend" || timeFrame === "nextWeekend") {
    const dateToInteger = Date.parse(startDate);
    const datePlusOffset = dateToInteger + 86400000;
    const endDateDate = new Date(datePlusOffset);
    // console.log(startDate, dateToInteger, datePlusOffset, endDate);
    // console.log("DateCard 29: ", endDateDate);
    outDate = `${startDateString}–<br />${getDateDayNoTimeZone(endDateDate)}`;
  }
  if (timeFrame === "nextWeek") {
    // console.log(timeFrame);
    // TODO: THIS NEEDS TO BE SUNDAY TO SATURDAY, NOT SATURDAY TO FRIDAY
    const nextWeekStartDate = new Date(Number(dateAsDate) + 86400000);
    const endDateDate = new Date(Number(dateAsDate) + 86400000 * 7);
    // console.log("DateCard 34: ", endDateDate);
    outDate = `${getDateDayNoTimeZone(
      nextWeekStartDate
    )}–<br />${getDateDayNoTimeZone(endDateDate)}`;
  }

  return (
    <DateCardDiv>
      <h2 dangerouslySetInnerHTML={{ __html: outDate }} />
    </DateCardDiv>
  );
};
const IntroCard = ({ intro }) => (
  <DateCardDiv>
    <h2>{intro}</h2>
  </DateCardDiv>
);

const EventCard = ({ thisEvent }) => {
  
  const isThereAnEndDate = Boolean(thisEvent.eventEndDateTime);
  const endDateToUse = isThereAnEndDate
    ? thisEvent.eventEndDateTime
    : thisEvent.eventStartDateTime;
  const hasItStarted =
    cleanCraftDate(thisEvent.eventStartDateTime) < new Date() ;
  // console.log(hasItStarted);

  const topLine =
    thisEvent && thisEvent.eventType && thisEvent.eventType.length ? (
      <H6>
        {thisEvent.eventLocation[0] && thisEvent.eventLocation[0].slug ? (
          thisEvent.eventLocation[0].typeHandle === "communityMember" ? (
            <Link to={`/community/${thisEvent.eventLocation[0].slug}/`}>
              {thisEvent.eventLocation[0].title}
            </Link>
          ) : (
            <a>{thisEvent.eventLocation[0].title}</a>
          )
        ) : null}
      </H6>
    ) : null;

  const startDate =
    getShortDayNoTimeZone(cleanCraftDate(thisEvent.eventStartDateTime)) ===
    getShortDayNoTimeZone(cleanCraftDate(endDateToUse)) ? (
      <H6Medium>
        {getShortDayNoTimeZone(cleanCraftDate(thisEvent.eventStartDateTime))}
      </H6Medium>
    ) : (
      <React.Fragment>
        <H6Medium>
          {cleanCraftDate(thisEvent.eventEndDateTime) < new Date()?
              (
                <>
                {hasItStarted
                ? ""
                : `${getShortDayNoTimeZone(
                    cleanCraftDate(thisEvent.eventStartDateTime)
                  )}–`}
              {getShortDayNoTimeZone(cleanCraftDate(endDateToUse))}
                </>
              )
              :
              (
                <>
                {hasItStarted
                  ? `${getShortDayNoTimeZone(
                    cleanCraftDate(thisEvent.eventStartDateTime)
                  )} - `
                  : `${getShortDayNoTimeZone(
                      cleanCraftDate(thisEvent.eventStartDateTime)
                    )} – `}
                {getShortDayNoTimeZone(cleanCraftDate(endDateToUse))}
                </>
              )}
          
        </H6Medium>
      </React.Fragment>
    );

    //console.log(thisEvent.headerImage);

  return (
    <EventCardDiv size={thisEvent.focusLevel}>
      <Link to={`/event/${thisEvent.slug}/`}>
       {thisEvent.listingImage && thisEvent.listingImage.length && thisEvent.listingImage[0].card ? (
          <Img
            fixed={thisEvent.listingImage[0].card.childImageSharp.fixed}
            alt={thisEvent.title}
            className="xyz"
          />
        ):(
          <>
          {thisEvent.headerImage.length && thisEvent.headerImage[0].card ? (
            thisEvent.headerImage[0].card.childImageSharp ? (
              <Img
                fixed={thisEvent.headerImage[0].card.childImageSharp.fixed}
                alt={thisEvent.title}
                className="abc"
              />
            ) : (
              <img
                src={thisEvent.headerImage[0].card.publicURL}
                alt={thisEvent.title}
              />
            )
          ) : (
            <div className="placeholder" />
          )}
          </>
          
            

        )}
        
      </Link>
      <div>
        <H6SmallMedium>
          {getEventTypeFromID(thisEvent.eventType[0])}
        </H6SmallMedium>
        {topLine}
        {startDate}
        <H5Strong>
          <Link to={`/event/${thisEvent.slug}/`}>{thisEvent.title}</Link>
        </H5Strong>
        <div
          dangerouslySetInnerHTML={{ __html: thisEvent.eventShortDescription }}
        />
      </div>
    </EventCardDiv>
  );
};

const ArticleCard = ({ thisArticle, accentColor }) => {
   //console.log(thisArticle);
  return (
    <EventCardDiv
      size={thisArticle.focusLevel || 0}
      color={accentColor || "var(--purple)"}
    >
      
      <Link to={`/${thisArticle.typeHandle}/${thisArticle.slug}/`}>
      {thisArticle.listingImage && thisArticle.listingImage.length && thisArticle.listingImage[0].card ? (
        <Img
          fixed={thisArticle.listingImage[0].card.childImageSharp.fixed}
          alt={thisArticle.title} 
          className="xyz"
        />
      ):(
        <>
        {thisArticle.headerImage.length && thisArticle.headerImage[0].card ? (
            thisArticle.headerImage[0].card.childImageSharp ? (
              <Img
                fixed={thisArticle.headerImage[0].card.childImageSharp.fixed}
                alt={thisArticle.title}
                className="abc"
              />
            ) : (
              <img
                src={thisArticle.headerImage[0].card.publicURL}
                alt={thisArticle.title}
              />
            )
          ) : (
            <div className="placeholder" />
          )}
        </>
      )}
        
      </Link>
      <div>
        <H6SmallMedium>{thisArticle.editorialType[0]}</H6SmallMedium>
        {/*<H6>
        <strong>Starts</strong>
        {getTime(thisArticle.eventStartDateTime)}
      </H6>
      <H6>
        <strong>Ends</strong>
        {getTime(thisArticle.eventEndDateTime)}
</H6>*/}
        <H5Strong>
          <Link to={`/${thisArticle.typeHandle}/${thisArticle.slug}/`}>
            {thisArticle.title}
          </Link>
        </H5Strong>
        <div
          dangerouslySetInnerHTML={{
            __html: thisArticle.editorialShortDescription,
          }}
        />
      </div>
    </EventCardDiv>
  );
};

const EventGrid = ({
  events,
  noDates,
  accentColor,
  textColor,
  introCard,
  useBottomRule,
  usePadding,
  firstDate,
  firstDateType,
  showDate,
  isProgramme,
  featuredEventsTitle
}) => {
  const { useStaging } = React.useContext(GlobalContext);
  // console.log(events.map((x) => x.headerImage[0].card));
  const [eventDateList, setEventDateList] = React.useState();
  React.useEffect(() => {
    const eventsWithDates = [];
    // first, sort all the events by date

    if (events && events.length) {
      // console.log(useStaging, events);
      const sortedEvents = events
        .filter(useStaging ? (x) => x : (x) => !x.isStaging)
        .sort((x, y) => {
          if (
            cleanCraftDate(x.eventStartDateTime) <
            cleanCraftDate(y.eventStartDateTime)
          ) {
            return -1;
          }
          if (
            cleanCraftDate(x.eventStartDateTime) >
            cleanCraftDate(y.eventStartDateTime)
          ) {
            return 1;
          }
          return 0;
        });
      // second, make a list sorted by date
      const now = firstDate || new Date();
      // sortedEvents.map((x) => {
      //   console.log(x.eventStartDateTime, cleanCraftDate(x.eventStartDateTime));
      // });

      for (let i = 0; i < sortedEvents.length; i++) {
        let myDate = cleanCraftDate(sortedEvents[i].eventStartDateTime);
        const startTime = cleanCraftDate(sortedEvents[i].eventStartDateTime);
        if (startTime < now) {
          // if date < today's date, concatenate
          myDate = String(now);
        }

        if(sortedEvents[i].id != "event_event_Entry:74992:1") {

          if (eventsWithDates[myDate]) {
            eventsWithDates[myDate].events[
              eventsWithDates[myDate].events.length
            ] = sortedEvents[i];
          } else {
            eventsWithDates[String(myDate)] = {
              events: [sortedEvents[i]],
            };
          }

        }
      }

      // TODO: Maybe this should be setting event.focusLevel based on how many events there are for a particular day?
      // e.g. 1: superwide
      //      2: normal, normal
      //      3: narrow, narrow, narrow
      //      4: superwide, normal, normal, normal

      setEventDateList(
        Object.keys(eventsWithDates).map((date) => {
          const dateAsDate = new Date(date);
          return {
            date: date,
            dateAsDate: dateAsDate,
            events: eventsWithDates[date].events,
            timeFrame: firstDateType || null,
          };
        })
      );
    }
  }, [events]);
  // console.log(firstDateType, eventDateList);
  return (
    <EventGridHolder
      textColor={textColor || "var(--black)"}
      accentColor={accentColor || "var(--red)"}
      useBottomRule={useBottomRule || false}
      usePadding={usePadding || false}
    >
      {noDates ? (
        <EventGridDiv>
          {introCard ? <IntroCard intro={introCard} /> : null}
          {events.length ? (
            events
              .filter(useStaging ? (x) => x : (x) => !x.isStaging)
              .map((thisEvent, index) => (
                <ArticleCard
                  key={index}
                  thisArticle={thisEvent}
                  accentColor={accentColor}
                />
              ))
          ) : (
            <EmptyEventGrid />
          )}
        </EventGridDiv>
      ) : eventDateList && eventDateList.length ? (
        eventDateList.map((eventDate, index2) => (
          <EventGridDiv key={index2}>
            {showDate!=false?(
              <DateCard
                date={eventDate.date}
                dateAsDate={eventDate.dateAsDate}
                timeFrame={eventDate.timeFrame}
              />
            ):

            <>
            {index2==0?(
              <DateCardDiv>
              <h2 dangerouslySetInnerHTML={{ __html: featuredEventsTitle }} />
              </DateCardDiv>
            ):null}
            </>

            
            }
            
            {eventDate.events.map((thisEvent, index) => (
              <EventCard key={index} thisEvent={thisEvent} />
            ))}
          </EventGridDiv>
        ))
      ) : (

        
        <EmptyEventGrid/>
        
      )}
    </EventGridHolder>
  );
};

export default EventGrid;

export const EmptyEventGrid = ({ children }) => {
  const emptyQuery = useStaticQuery(graphql`
    query {
      craftEventsFrontEventsFrontEntry {
        eventFrontPageNoEventsImage {
          ... on Craft_communityAssets_Asset {
            localFile {
              publicURL
              childImageSharp {
                fixed(width: 1240) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
          ... on Craft_eventsAssets_Asset {
            localFile {
              publicURL
              childImageSharp {
                fixed(width: 1240) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    }
  `).craftEventsFrontEventsFrontEntry.eventFrontPageNoEventsImage[0];
  return (
    <EmptyEventGridDiv>
      <div>
        {emptyQuery.localFile.childImageSharp ? (
          <Img
            fixed={emptyQuery.localFile.childImageSharp.fixed}
            className="desktop"
          />
        ) : (
          <img
            src={emptyQuery.localFile.publicURL}
            width="100%"
            height="auto"
            className="desktop"
          />
        )}
        <img
          src={emptyQuery.localFile.publicURL}
          width="100%"
          height="auto"
          className="mobile"
        />
      </div>
    </EmptyEventGridDiv>
  );
};
