import styled from "styled-components";

export const EventGridHolder = styled.div`
  --rowGap: 50px;
  margin-top: 50px;
  display: grid;
  grid-template-rows: auto;
  grid-row-gap: 50px;
  padding-left: ${(props) => (props.usePadding ? "var(--outerMargin)" : 0)};
  padding-right: ${(props) => (props.usePadding ? "var(--outerMargin)" : 0)};
  --textColor: ${(props) =>
    props.textColor ? props.textColor : "var(--black)"};
  --accentColor: ${(props) =>
    props.accentColor ? props.accentColor : "var(--red)"};
  --bottomRule: ${(props) =>
    props.useBottomRule ? "1.5px solid var(--accentColor)" : "none"};
  --bottomPadding: ${(props) => (props.useBottomRule ? "20px" : 0)};
  @media (max-width: 1439px) {
    justify-content: center;
  }
  @media (max-width: 767px) {
    --rowGap: 20px;
  }
`;

export const EventGridDiv = styled.div`
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 400px 400px 400px;
  grid-column-gap: 20px;
  grid-row-gap: var(--rowGap);
  grid-auto-flow: dense;
  margin-bottom: 50px;
  @media (max-width: 1439px) {
    grid-template-columns: 400px 400px;
  }
  @media (max-width: 1023px) {
    grid-template-columns: 400px;
  }
  @media (max-width: 767px) {
    grid-template-columns: 375px;
    margin-bottom: 0;
  }
`;

export const DateCardDiv = styled.div`
  display: flex;
  border-top: 1.5px solid var(--accentColor);
  padding-top: 20px;
  width: ${(props) =>
    props.size === 1 || props.size === 2
      ? "var(--cardWidthWide)"
      : "var(--cardWidthNormal)"};
  color: var(--accentColor);
  border-bottom: var(--bottomRule);
  padding-bottom: var(--bottomPadding);
  @media (max-width: 767px) {
    width: 375px;
    & h2 {
      width: 100%;
      text-align: center;
      margin: 0;
    }
  }
`;

export const EventCardDiv = styled.div`
  --color: ${(props) => props.color || "var(--red)"};
  --cardWidthExtraWide: 820px;
  --cardWidthWide: 820px;
  --cardWidthNormal: 400px;
  --extraWideWidth: 610px;
  --extraWideHeight: 450px;
  --wideWidth: 400px;
  --wideHeight: 300px;
  --normalWidth: 190px;
  --normalHeight: 190px;
  display: flex;
  border-top: 1.5px solid var(--color);
  padding-top: 20px;
  width: ${(props) =>
    props.size === 2
      ? "var(--cardWidthExtraWide)"
      : props.size === 1
      ? "var(--cardWidthWide)"
      : "var(--cardWidthNormal)"};
  grid-column-end: ${(props) =>
    props.size === 2 || props.size === 1 ? "span 2" : "span 1"};
  border-bottom: var(--bottomRule);
  padding-bottom: var(--bottomPadding);

  & > a > .gatsby-image-wrapper {
    object-fit: cover;
    margin-right: 20px;
    height: ${(props) =>
      props.size === 2
        ? "var(--extraWideHeight)"
        : props.size === 1
        ? "var(--wideHeight)"
        : "var(--normalHeight)"} !important;
    min-width: ${(props) =>
      props.size === 2
        ? "var(--extraWideWidth)"
        : props.size === 1
        ? "var(--wideWidth)"
        : "var(--normalWidth)"};
    max-width: ${(props) =>
      props.size === 2
        ? "var(--extraWideWidth)"
        : props.size === 1
        ? "var(--wideWidth)"
        : "var(--normalWidth)"};
  }
  & > a > div {
    margin-right: 20px;
  }
  & > a > .placeholder {
    width: 190px;
    height: 190px;
    background-color: var(--color);
  }
  & h6 a {
    color: var(--textColor);
  }
  & > div {
    & > h6:first-of-type {
      color: var(--color);
      font-weight: 600;
    }
    & > h6 + h6 {
      margin-top: 12px;
      & strong {
        display: inline-block;
        width: 40px;
      }
    }
    & > h5 {
      margin-top: 12px;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2; /* number of lines to show */
      -webkit-box-orient: vertical;
      overflow: hidden;
      & a {
        color: var(--textColor);
      }
    }
    & > div {
      font-size: 12px;
      line-height: 18px;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 4; /* number of lines to show */
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
  @media (max-width: 767px) {
    flex-wrap: wrap;
    justify-content: center;
    width: 375px;
    margin-bottom: 20px;
    margin-bottom: 0;
    & > div {
      padding: 0 25px;
      & h6 {
        text-align: center;
        margin-top: 15px;
      }
      & h5 {
        text-align: center;
      }
    }
  }
  @media (max-width: 1439px) {
    --cardWidthWide: 400px;
    --wideWidth: 190px;
    --wideHeight: 190px;
    /* & .gatsby-image-wrapper {

		} */
  }
  @media (max-width: 1023px) {
    --cardWidthExtraWide: 400px;
    --extraWideWidth: 190px;
    --extraWideHeight: 190px;
    grid-column-end: span 1;
  }
  @media (max-width: 767px) {
    & > div > div {
      text-align: center;
    }
  }
`;

export const EmptyEventGridDiv = styled.div`
  --accentColor: var(--red);
  margin-top: 25px;
  margin-bottom: 25px;
  & .mobile {
    display: none !important;
  }
  @media (max-width: 1439px) {
    & .desktop {
      display: none !important;
    }
    & .mobile {
      display: block !important;
    }
  }
`;
