import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import GlobalContext from "./../GlobalContext";
import ContentTypeSelector from "./../ContentTypeSelector";
import EditorialFilter from "./../EditorialFilter";
import EventGrid from "./../EventGrid";
import { ArticleFilterDiv } from "./elements";

const contentTypeColor = {
  words: "var(--green)",
  sounds: "var(--red)",
  images: "var(--purple)",
  videos: "var(--yellow)",
};

const contentTypeIndex = { words: 0, sounds: 1, images: 2, videos: 3 };

const ArticleFilter = ({ backgroundColor, defaultType, isFront }) => {
  const { useStaging } = React.useContext(GlobalContext);
  const articleQuery = useStaticQuery(graphql`
    query {
      sounds: allCraftEntryInterface(
        filter: { typeHandle: { eq: "sounds" } }
        sort: { fields: dateCreated, order: DESC }
      ) {
        nodes {
          ... on Craft_articles_sounds_Entry {
            id
            title
            slug
            isStaging
            typeHandle
            editorialType
            editorialShortDescription
            hideInPerspectivesPage
            listingImage {
              ... on Craft_editorialAssets_Asset {
                card: localFile {
                  publicURL
                  childImageSharp {
                     fixed(height: 190, width: 190, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_communityAssets_Asset {
                card: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 190, width: 190, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
            headerImage {
              ... on Craft_editorialAssets_Asset {
                card: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 190, width: 190, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
          }
        }
      }
      words: allCraftEntryInterface(
        filter: { typeHandle: { eq: "words" } }
        sort: { fields: dateCreated, order: DESC }
      ) {
        nodes {
          ... on Craft_articles_words_Entry {
            id
            title
            slug
            isStaging
            typeHandle
            editorialType
            editorialShortDescription
            hideInPerspectivesPage
            listingImage {
              ... on Craft_editorialAssets_Asset {
                card: localFile {
                  publicURL
                  childImageSharp {
                     fixed(height: 190, width: 190, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_communityAssets_Asset {
                card: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 190, width: 190, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
            headerImage {
              ... on Craft_editorialAssets_Asset {
                card: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 190, width: 190, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
          }
        }
      }
      video: allCraftEntryInterface(
        filter: { typeHandle: { eq: "videos" } }
        sort: { fields: dateCreated, order: DESC }
      ) {
        nodes {
          ... on Craft_articles_videos_Entry {
            id
            title
            slug
            isStaging
            typeHandle
            editorialType
            editorialShortDescription
            hideInPerspectivesPage
            dateCreated
            listingImage {
              ... on Craft_editorialAssets_Asset {
                card: localFile {
                  publicURL
                  childImageSharp {
                     fixed(height: 190, width: 190, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_communityAssets_Asset {
                card: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 190, width: 190, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
            headerImage {
              ... on Craft_editorialAssets_Asset {
                card: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 190, width: 190, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
          }
        }
      }
      images: allCraftEntryInterface(
        filter: { typeHandle: { eq: "images" } }
        sort: { fields: dateCreated, order: DESC }
      ) {
        nodes {
          ... on Craft_articles_images_Entry {
            id
            title
            slug
            isStaging
            typeHandle
            editorialType
            editorialShortDescription
            hideInPerspectivesPage
            listingImage{
              ... on Craft_editorialAssets_Asset {
                card: localFile {
                  publicURL
                  childImageSharp {
                     fixed(height: 190, width: 190, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_communityAssets_Asset {
                card: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 190, width: 190, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
            headerImage {
              ... on Craft_editorialAssets_Asset {
                card: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 190, width: 190, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);
  const unfilteredEverything = [
    ...articleQuery.images.nodes,
    ...articleQuery.sounds.nodes,
    ...articleQuery.video.nodes,
    ...articleQuery.words.nodes,
  ].filter(useStaging ? (x) => x : (x) => !x.isStaging);
  // const everything = [
  //   ...articleQuery.images.nodes,
  //   ...articleQuery.sounds.nodes,
  //   ...articleQuery.video.nodes,
  //   ...articleQuery.words.nodes,
  // ].filter(useStaging ? (x) => x : (x) => !x.isStaging);
  const everything = unfilteredEverything.filter((x) => {
    const a=(x.hideInPerspectivesPage 
            && x.hideInPerspectivesPage 
            && x.hideInPerspectivesPage=='yes'?'hide':'show')
    return (a=='show');
  });
 
  // console.log(useStaging, everything);
  // console.log(defaultType);
  // console.log(everything.map((x) => x.headerImage[0]));
  const [shownArticles, setShownArticles] = React.useState(everything);
  const [
    currentArticleTypeHandle,
    setCurrentArticleTypeHandle,
  ] = React.useState(defaultType || "words");
  React.useEffect(() => {
    // This is in here because the content seletor doesn't have an unset state!
    // this should only run first time this is rendered!
    const newSet = everything.filter(
      (x) => x.typeHandle === currentArticleTypeHandle
    );
    setShownArticles(newSet);
  }, []);
  // console.log(contentTypeColor, currentArticleTypeHandle);
  const textColor =
    backgroundColor === "var(--black)" ? "var(--white)" : "var(--black)";
  return (
    <ArticleFilterDiv backgroundColor={backgroundColor} color={textColor}>
      <ContentTypeSelector
        defaultSelection={contentTypeIndex[currentArticleTypeHandle]}
        onSelection={(e) => {
          setCurrentArticleTypeHandle(e);
          const newSet = everything.filter((x) => x.typeHandle === e);
          setShownArticles(newSet);
        }}
      />
      <EditorialFilter
        selected={(e) => {
          // console.log(e);
          // console.log(shownArticles);
          if (e.length) {
            const newList = everything.filter((x) => {
              let found = false;
              for (let i = 0; i < x.editorialType.length; i++) {
                if (e.indexOf(x.editorialType[i]) > -1) {
                  found = true;
                }
              }
              return found;
            });
            setShownArticles(
              newList.filter((x) => x.typeHandle === currentArticleTypeHandle)
            );
          } else {
            setShownArticles(
              everything.filter(
                (x) => x.typeHandle === currentArticleTypeHandle
              )
            );
          }
        }}
      />
      
      <EventGrid
        events={shownArticles}
        noDates
        textColor={textColor}
        accentColor={contentTypeColor[currentArticleTypeHandle]}
        usePadding={
          !isFront
        } /* THIS SHOULD NOT BE USED ON FRONT, BUT NEEDS TO HAPPEN ON ARTICLE PAGES */
      />
    </ArticleFilterDiv>
  );
};

export default ArticleFilter;
