import styled from "styled-components";

export const ArticleFilterDiv = styled.div`
  --backgroundColor: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "var(--white)"};
  --color: ${(props) => (props.color ? props.color : "var(--black)")};
  max-width: var(--width);
  margin-left: auto;
  margin-right: auto;
  padding-left: var(--outerMargin);
  padding-right: var(--outerMargin);
  padding: 0;
  background-color: var(--backgroundColor);
  color: var(--color);
  & > nav ul {
    z-index: 1;
  }
  & > div > div {
    z-index: 2;
  }
  /* @media (min-width: 1441px) { */
  position: relative;
  &:before {
    z-index: 0;
    background-color: var(--backgroundColor);
    content: "";
    position: absolute;
    height: calc(25px + 100%);
    left: -2000px;
    right: -2000px;
    top: 0;
    bottom: -25px;
  }
  /* } */
`;
