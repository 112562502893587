import React from "react";
import PropTypes from "prop-types";
import { ContentTypeSelectorDiv } from "./elements";
import { H4Big } from "./../Typography";
import Img from "gatsby-image";

import images from "./images/images.jpg"; 
import sounds from "./images/sounds.jpg"; 
import videos from "./images/video.jpg"; 
import words from "./images/words.png"; 


const tabs = [
  { color: "var(--green)", name: "Words", id: "words" },
  { color: "var(--red)", name: "Sounds", id: "sounds" },
  { color: "var(--purple)", name: "Images", id: "images" },
  { color: "var(--yellow)", name: "Videos", id: "videos" },
];

const imgs = [images , sounds ,videos ,words ];

/**
 * This passes back the name of the section (to `onSelection`); we probably need a bigger wrapper component to handle this
 * (could be like TabSet, could be some kind of filter)
 *
 * if `defaultSelection` is set, it will default to the chosen number of the four sections
 *
 */

const ContentTypeSelector = ({ defaultSelection, onSelection }) => {
  const [currentTab, setCurrentTab] = React.useState(defaultSelection || 0);
  return (
    <ContentTypeSelectorDiv color={tabs[currentTab].color}>
      <ul>
        {tabs.map((thisTab, index) => (
          <li className={index === currentTab ? "selected" : ""} key={index}>
            <H4Big>
            {index !== currentTab ? (
              <img src={imgs[index]} loading="lazy"/>
            ) : null}
              
              <a
                href="/#"
                onClick={(e) => {
                  e.preventDefault();
                  setCurrentTab(index);
                  onSelection(tabs[index].id);
                }}
              > 
                
                <span>{thisTab.name} </span>
              </a>
            </H4Big>
          </li>
        ))}
      </ul>
    </ContentTypeSelectorDiv>
  );
};

export default ContentTypeSelector;

ContentTypeSelector.propTypes = {
  onSelection: PropTypes.func,
  defaultSelection: PropTypes.oneOf([0, 1, 2, 3]),
};
