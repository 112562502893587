import styled from "styled-components";
import wordsImage from "./images/words.png";
import imagesImage from "./images/images.jpg";
import soundsImage from "./images/sounds.jpg";
import videosImage from "./images/video.jpg";

export const ContentTypeSelectorDiv = styled.nav`
  --color: ${(props) => props.color};
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 35px;
  padding-bottom: 25px;
  & ul {
    list-style-type: none;
    display: inline-flex;
    margin: 0;
    padding: 0;
    user-select: none;
    & li {
      width: 306px;
      height: 45px;
      background-size: 100% 100%;
      border-top: 2px solid var(--color);
      /* transition: 0.5s; */
      text-align: center;
      user-select: none;
      & a{
        
        & span{
          position:absolute;
        }
      }
      & h4 {
        position: relative;
        display: inline-block;
        margin: 0;
        width: 100%;
        height: 100%;
        user-select: none;
        & img{
          width:100%;
          height:100%;
          object-fit: cover;
          position:relative;
        }
      }
      // &:first-of-type {
      //   background-image: url(${wordsImage});
      // }
      // &:nth-of-type(2) {
      //   background-image: url(${soundsImage});
      // }
      // &:nth-of-type(3) {
      //   background-image: url(${imagesImage});
      // }
      // &:nth-of-type(4) {
      //   background-image: url(${videosImage});
      // }
      &.selected {
        background: var(--color);
      }
      & + li {
        margin-left: 5px;
      }
      & a {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 5px 12px;
        width: 100%;
        height: 100%;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        color: var(--white);
      }
    }
  }
  @media (max-width: 1439px) {
    /* max-width: 100%; */
    overflow-x: scroll;
    justify-content: flex-start;
    justify-content: center;
    & ul li {
      width: 202px;
    }
  }
  @media (max-width: 1023px) {
    & ul li {
      width: 175px;
    }
  }
  @media (max-width: 767px) {
    justify-content: center;
    & ul {
      & li {
        width: 80px;
        height: 30px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        & h4 {
          font-size: 16px;
          & a {
            padding: 0;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }
`;
